import React from 'react';
import './About.css';

function About() {
  return (
    <div className='aboutContainer'>
      <div className='aboutText'>
        Bailamigos är en dansskola som erbjuder kurser i Bachata och Salsa.
        Vi har kurser för både barn och vuxna på alla nivåer, från nybörjare till avancerad.
        <br />
        Skolan grundades av Ricki Gonzales, en passionerad dansare och instruktör.
        Ricki har lång erfarenhet inom dansvärlden och är välkänd för sin unika stil och energi.
        För mer om Rickis arbete och inspiration, följ honom på Instagram.
      </div>
    </div>
  );
}

export default About;