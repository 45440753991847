import React, { useState } from 'react';
import './RegistrationForm.css';

function CourseForm() {
  const [formData, setFormData] = useState({
    name: '',
    personnummer: '',
    address: '',
    email: '',
    role: '',
    kurs: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    alert('Formulär skickades framgångsrikt!');
  };

  return (
    <div>
      <p style={{color: 'red'}}> * Just nu är denna funktion INTE tillgängligt</p>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Namn</label>
          <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Ange ditt för och efternamn" required />
        </div>
        <div>
          <label>Personnr</label>
          <input type="text" name="personnummer" value={formData.personnummer} onChange={handleChange} placeholder="yyyymmddnnnn"required />
        </div>
        <div>
          <label>Adress</label>
          <input type="text" name="address" value={formData.address} onChange={handleChange} placeholder="exempelgatan 23" required />
        </div>
        <div>
          <label>Mejl</label>
          <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="exempel@xmail.com" required />
        </div>
        <div>
          <label>Roll</label>
          <select name="role" value={formData.role} onChange={handleChange} required>
            <option value="">Välj</option>
            <option value="förare">Förare</option>
            <option value="följare">Följare</option>
          </select>
        </div>
        <div>
          <label>Kurs</label>
          <select name="kurs" value={formData.kurs} onChange={handleChange} required>
            <option value="">Välj</option>
            <option value="förare">Bachata nybörjare</option>
            <option value="följare">Bachata forts del 1</option>
            <option value="följare">Bachata forts del 2</option>
          </select>
        </div>
        <button type="submit">Anmäla till kursen</button>
      </form>
    </div>
  );
}

export default CourseForm;