import React from 'react';
import RegistrationForm from '../components/RegistrationForm';
import './Registration.css';

function Registration() {
  return (
    <div>
      <RegistrationForm />
    </div>
  );
}

export default Registration;
