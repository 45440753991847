import React from 'react';
import ContactForm from '../components/ContactForm';
import './Contact.css';

function Contact() {
  return (
    <div className='contact'>
      <ContactForm />
    </div>
  );
}

export default Contact;
